import { Link, useParams } from '@pankod/refine-react-router-v6';
import React, { useEffect, useState } from 'react';
import { getOfferInfo } from '../api/getOfferInfo';
import { IOfferInfoResponseNormalize } from '../model/types';
import { useOfferInfoState } from '../model/useOfferInfoState';
import { Tag } from 'antd';
import {
  ButtonStyled,
  OfferActionButtonStyled,
  OfferBodyStyled,
  OfferColumnStyled,
  OfferCommentRowStyled,
  OfferHeaderStyled,
  OfferIndexStyled,
  OfferRowStyled,
  OfferTitleStyled,
} from './Offer.styles';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { formatSecondsToTime } from '../../../shared/helpers/formatSecondsToTime';
import { CountryFlag } from '../../../shared/components/CountryFlag';
import { useModalState } from '../../../shared/components/ui/RenderModal/model/useModalState';
import { ModalKeys } from '../../../shared/components/ui/RenderModal/model/enums';
import { RenderModal } from '../../../shared/components/ui/RenderModal';

// вся инфа об оффере, не включая табы (сделки)
const OfferInfo = () => {
  const { t } = useTranslation(['shared']);

  let { id } = useParams();

  const [isCommentShort, setIsCommentShort] = useState<boolean>(true);
  const [isCommentFull, setIsCommentFull] = useState<boolean>(false);

  const [offerInfo, setOfferInfo] = useState<IOfferInfoResponseNormalize>();

  const { isBuyingCrypto, setIsBuyingCrypto, offerStatus, setOfferStatus } =
    useOfferInfoState();

  const exchangeFreezeSecondsFormat = formatSecondsToTime(
    offerInfo?.exchangeFreezeSeconds ?? 0
  );

  const showFullCommentHandler = () => {
    setIsCommentFull((prevState) => !prevState);
  };

  useEffect(() => {
    id &&
      getOfferInfo(id).then((data) => {
        if (data) {
          return setOfferInfo(data);
        }
      });
  }, []);

  useEffect(() => {
    if (offerInfo?.id) {
      setIsBuyingCrypto(offerInfo?.isBuyingCrypto);
      setOfferStatus(offerInfo?.status);
    }
    if (offerInfo?.comment?.length && offerInfo?.comment?.length >= 160) {
      setIsCommentShort(false);
    } else if (offerInfo?.comment?.length && offerInfo?.comment?.length < 160) {
      setIsCommentShort(true);
    }
  }, [offerInfo?.id]);
  const { openModal } = useModalState();
  const handleOpenModalCancel = (
    id?: number,
    isBuyingCrypto?: boolean,
    cryptoCurrencyCode?: string
  ) => {
    openModal(ModalKeys.ModalCancelOffer, {
      id: id,
      isBuyingCrypto: isBuyingCrypto,
      cryptoCurrencyCode: cryptoCurrencyCode,
      callback: () => setOfferStatus('declined'),
    });
  };
  const handleClick = () => {
    handleOpenModalCancel(
      offerInfo?.id,
      offerInfo?.isBuyingCrypto,
      offerInfo?.cryptoPaymentSystemCurrency.currencyKey
    );
  };

  return (
    <>
      <OfferHeaderStyled>
        <OfferTitleStyled>
          {isBuyingCrypto
            ? t('shared:тексты.Оффер на покупку USDT')
            : t('shared:тексты.Оффер на продажу USDT')}
        </OfferTitleStyled>
        {offerStatus === 'active' && (
          <Tag color={'blue'}>{t('shared:тексты.Активен')}</Tag>
        )}
        {offerStatus === 'done' && (
          <Tag color={'cyan'}>{t('shared:тексты.Закрыт')}</Tag>
        )}
        {offerStatus === 'declined' && (
          <Tag color={'red'}>{t('shared:тексты.Отменен')}</Tag>
        )}
        {(offerStatus === 'active' || offerStatus === 'done') && (
          <OfferActionButtonStyled onClick={handleClick}>
            <CloseOutlined style={{ color: '#FF4D4F' }} />{' '}
            {t('shared:кнопки.Отменить')}
          </OfferActionButtonStyled>
        )}
      </OfferHeaderStyled>
      <OfferIndexStyled>{offerInfo?.name}</OfferIndexStyled>
      <OfferBodyStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Актив двоеточие')}</div>
            <div>
              <b>{offerInfo?.currentCryptoAmount}</b> {t('shared:тексты.из')}{' '}
              {offerInfo?.initialCryptoAmount}{' '}
              {offerInfo?.cryptoPaymentSystemCurrency?.currencyKey}
            </div>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Пункт обмена двоеточие')}</div>
            <Link to={`/points/${offerInfo?.exchangePoint?.id}`}>
              {offerInfo?.exchangePoint?.name}
            </Link>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Место обмена двоеточие')}</div>
            {offerInfo?.countryCode && (
              <>
                <CountryFlag
                  width={20}
                  height={14}
                  countryCode={offerInfo?.countryCode}
                />
                <div>{offerInfo?.city}</div>
              </>
            )}
          </OfferRowStyled>
          <OfferRowStyled>
            <div>
              {t('shared:тексты.Фикс точка стоимость сделки двоеточие')}
            </div>
            <div>
              {offerInfo?.cryptoCommissionAmount
                ? offerInfo?.cryptoCommissionAmount +
                  ' ' +
                  offerInfo?.cryptoPaymentSystemCurrency?.currencyKey
                : ' - '}
            </div>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Сумма сделки двоеточие')}</div>
            <div>
              {t('shared:тексты.от')}
              {offerInfo?.cashMinAmount}
              {t('shared:тексты.до')}
              {offerInfo?.cashMaxAmount}{' '}
              {offerInfo?.cashPaymentSystemCurrency?.currencyKey}
            </div>
          </OfferRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Курс клиента двоеточие')}</div>
            <b>
              {offerInfo?.clientRate}{' '}
              {offerInfo?.cashPaymentSystemCurrency?.currencyKey}
            </b>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Источник курса двоеточие')}</div>
            <div>{offerInfo?.exchangeRateSource}</div>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Курс без комиссии двоеточие')}</div>
            <div>
              {offerInfo?.exchangeRate}{' '}
              {offerInfo?.cashPaymentSystemCurrency?.currencyKey}
            </div>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Комиссия пункта обмена двоеточие')}</div>
            <div>
              {offerInfo?.commissionPercent
                ? offerInfo?.commissionPercent + '%'
                : ' - '}
            </div>
          </OfferRowStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Комиссия Edenex двоеточие')}</div>
            <div>{offerInfo?.initialCommissionPercent}%</div>
          </OfferRowStyled>
        </OfferColumnStyled>
        <OfferColumnStyled>
          <OfferRowStyled>
            <div>{t('shared:тексты.Срок заморозки курса двоеточие')}</div>
            <Tag>
              {exchangeFreezeSecondsFormat.dDisplay +
                t('shared:тексты.дней двоеточие') +
                exchangeFreezeSecondsFormat.hDisplay +
                t('shared:тексты.часов двоеточие') +
                exchangeFreezeSecondsFormat.mDisplay +
                t('shared:тексты.минут сокращенное')}
            </Tag>
          </OfferRowStyled>
          {offerInfo?.createdAt && (
            <OfferRowStyled>
              <div>{t('shared:тексты.Дата создания двоеточие')}</div>
              <div>
                {dayjs(offerInfo?.createdAt).format('DD.MM.YYYY 00:00')}
              </div>
            </OfferRowStyled>
          )}
          {offerInfo?.dateDeclined && (
            <OfferRowStyled>
              {offerInfo?.status === 'declined' && (
                <div>{t('shared:тексты.Дата отмены двоеточие')}</div>
              )}
              <div>
                {dayjs(offerInfo?.dateDeclined).format('DD.MM.YYYY 00:00')}
              </div>
            </OfferRowStyled>
          )}
          {offerInfo?.dateDone && (
            <OfferRowStyled>
              {offerInfo?.status === 'done' && (
                <div>{t('shared:тексты.Дата закрытия двоеточие')}</div>
              )}
              <div>{dayjs(offerInfo?.dateDone).format('DD.MM.YYYY 00:00')}</div>
            </OfferRowStyled>
          )}
          <OfferCommentRowStyled>
            <div>{t('shared:тексты.Комментарий двоеточие')}</div>
            {isCommentShort &&
              !!offerInfo?.comment &&
              !!offerInfo?.comment.length && <div>{offerInfo?.comment}</div>}
            {!isCommentShort &&
              !!offerInfo?.comment &&
              !!offerInfo?.comment.length && (
                <div>
                  {isCommentFull ? (
                    <span>{offerInfo?.comment}</span>
                  ) : (
                    <span>{offerInfo?.comment.slice(0, 160)}...</span>
                  )}
                  <ButtonStyled
                    onClick={showFullCommentHandler}
                    role={'button'}
                    type={'link'}
                  >
                    {isCommentFull
                      ? t('shared:тексты.Свернуть')
                      : t('shared:тексты.Ещё')}
                  </ButtonStyled>
                </div>
              )}
            {offerInfo?.comment === null && <div> - </div>}
          </OfferCommentRowStyled>
        </OfferColumnStyled>
      </OfferBodyStyled>
      <RenderModal currentModalKey={ModalKeys.ModalCancelOffer} />
    </>
  );
};
export default OfferInfo;
