export const reorderArray = (array: string[]): string[] => {
  // с бэка залетают в неправильном порядке экшены - функция их перемещает
  let newArray = [...array];

  if (newArray.includes('completed')) {
    const completedIndex = newArray.indexOf('completed');
    newArray.splice(completedIndex, 1);
    newArray.unshift('completed');
  }

  if (newArray.includes('expired')) {
    const expiredIndex = newArray.indexOf('expired');
    newArray.splice(expiredIndex, 1);
    newArray.push('expired');
  }

  if (newArray.includes('cancelled')) {
    const cancelledIndex = newArray.indexOf('cancelled');
    newArray.splice(cancelledIndex, 1);
    newArray.push('cancelled');
  }

  return newArray;
};
