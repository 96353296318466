import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {jwtDecode} from 'jwt-decode';
import {ACCOUNT_URL_API, API_URL, EDENEX_ACCOUNT_URL,} from 'packages/keycloak-client/constants';
import {axios} from '../exios';
import {AxiosResponse, default as baseAxios} from 'axios';
import {useAccessState} from '../../pages/clients/ui/StaffAccessCreationModal/model/useAccessState';
import {useExchangePointState} from './useExchangePointState';
import {deleteCookieByName, getCookieByName, setCookieByName} from '../helpers/controlCookies';

type TUserKC = {
    access_token: string | undefined;
    refresh_token: string | undefined;
    auth_time: number;
    profile: {
        email: string;
        email_verified: boolean;
        iat: number;
        sub: string;
    };
};

type TUserAPI = {
    account: {
        email: string;
        status: string;
        userId: number;
        uid: string;
        total: number;
        totalFiat: number;
    };
};

type TGetUserKC = {
    session: any;
    setCookie: any;
};

type TClientCardState = {
    isAuth: boolean;
    isLoading: boolean;
    serverTime: number;
    userKC: TUserKC;
    userAPI: TUserAPI;
    logout: () => void;
    getUserProfileFromAPI: () => Promise<void>;
    refreshToken: (config: AxiosResponse) => Promise<AxiosResponse | undefined>;
    getServerTime: () => Promise<any>;
    setServerTime: (value: number) => void;
    setIsAuth: (value: boolean) => void;
    resendRequest: (config: string, accessToken: string) => Promise<any>;
};

export type TDecodedToken = {
    acr: string;
    aud: string;
    azo: string;
    email: string;
    email_verified: boolean;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    preferred_username: string;
    scope: string;
    session_state: string;
    sid: string;
    sub: string;
    typ: string;
};

export const useAuthState = create<TClientCardState>()(
    devtools(
        (set, get) => ({
            isAuth: false,
            isLoading: false,
            serverTime: 0,
            userKC: {
                access_token: undefined,
                refresh_token: undefined,
                auth_time: 0,
                profile: {
                    email: '',
                    email_verified: false,
                    iat: 0,
                    sub: '',
                },
            },
            userAPI: {
                account: {
                    email: '',
                    status: 'active',
                    userId: 0,
                    uid: '',
                    total: 0,
                    totalFiat: 0,
                },
            },
            setIsAuth: (value: boolean) => {
                set(
                    produce((draft) => {
                        draft.isAuth = value;
                    })
                );
            },
            resendRequest: async (config: any, accessToken: string) => {
                // TODO: В процессе доработать функцию переотправки запросов
                if (config.url.includes('exchange-points/search')) {
                    console.debug(
                        'resendRequest exchange-points/search',
                        config.url.includes('exchange-points/search')
                    );
                    // TODO: getUserExchangePoints()
                    useExchangePointState.getState().searchExchangePointsForAccounts();
                }
                if (config.url.includes('user-accounts/search')) {
                    const searchParams = new URLSearchParams(window.location.search);
                    const pointId = searchParams.get('id');

                    console.debug(
                        'resendRequest user-accounts/search',
                        config.url.includes('user-accounts/search')
                    );
                    // TODO: useAccessState.getPoints нужен id для отправки запроса
                    // TODO: useAccessState.searchExchangePoints
                    console.log('resendRequest pointId', pointId);

                    if (pointId) {
                        // TODO: useExchangePointState.searchSingleExchangePoint
                        useExchangePointState
                            .getState()
                            .searchSingleExchangePoint(parseInt(pointId, 10));
                    }

                    useAccessState.getState().getPointsStaff();
                }
            },
            refreshToken: async (config) => {
                try {
                    if (!getCookieByName('refresh_token')) return;

                    const res = await baseAxios.post(
                        `${ACCOUNT_URL_API}/server/edenex-account/api/refresh-token`,
                        {
                            refresh_token: getCookieByName('refresh_token')!.replace('Bearer ', '')
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: ''
                            }
                        }
                    );

                    const {access_token, refresh_token} = res!.data?.data;
                    const decodedToken: TDecodedToken = jwtDecode(access_token);

                    const oidcUser = getCookieByName('oidc.user');

                    if (config && refresh_token && access_token) {

                        setCookieByName(
                            'oidc.user',
                            JSON.stringify({
                                // @ts-ignore
                                ...oidcUser,
                                access_token: `Bearer ${access_token}`,
                                refresh_token
                            })
                        );

                        setCookieByName('email', decodedToken?.email);
                        setCookieByName('uid', decodedToken?.sub);
                        setCookieByName('token', access_token);
                        setCookieByName('refresh_token', refresh_token);

                        return await axios(config);
                    }
                } catch (error) {
                    console.error('error refresh token', String(error).includes('403'));
                    if (String(error).includes('403')) {
                        get().logout();
                    }
                }
            },
            getServerTime: async () => {
                try {
                    const res = await axios.get(
                        `${EDENEX_ACCOUNT_URL}/edenex-account/api/server-time`);
                    const {
                        data: {server_time},
                    } = res.data;
                    set(
                        produce((draft) => {
                            draft.setServerTime(server_time);
                        })
                    );
                } catch (e) {
                    console.error('getServerTime error', e);
                }
            },
            setServerTime: (value: number) => {
                set(
                    produce((draft) => {
                        draft.serverTime = value;
                    })
                );
            },
            logout: () => {
                localStorage.clear();

                deleteCookieByName('oidc.user');
                deleteCookieByName('remainingTime');
                deleteCookieByName('refreshTokenTimestamp');
                deleteCookieByName('currentServerTime');
                deleteCookieByName('token');
                deleteCookieByName('refresh_token');
                deleteCookieByName('email');
                deleteCookieByName('uid');
                deleteCookieByName('email_verified');
                deleteCookieByName('inviteLink');
                deleteCookieByName('inviteToken');
                deleteCookieByName('fromEdenex');
                deleteCookieByName('fromFinms');
                deleteCookieByName('fromPortal');
                deleteCookieByName('currentPointId');

                get().setIsAuth(false);
            },

            getUserProfileFromAPI: async () => {
                const response = await axios.get(`${API_URL}/me`);
                if (!!response) {
                    set(
                        produce((draft) => {
                            draft.userAPI = response.data;
                        }),
                        false,
                        {
                            type: 'useAuthStore => getUserProfileFromAPI',
                        }
                    );
                }
            },
        }),
        {
            anonymousActionType: 'useAuthState action',
            name: 'useClientStateV2',
        }
    )
);
