import BreadcrumbRequestPage from './BreadcrumbRequestPage';
import {
  PageWrapper,
  RequestInfoWrapper,
  SpinWrapperStyled,
} from './OfferRequestPage.styles';
import RequestInfo from './RequestInfo/RequestInfo';
import { useParams } from '@pankod/refine-react-router-v6';
import useOfferRequest from '../model/hooks/useOfferRequest';
import { Spin } from '@pankod/refine-antd';
import OfferRequestTabs from './offer-request-tabs/OfferRequestTabs';

const OfferRequestPage = () => {
  const { id: requestId } = useParams();
  const {
    refetch: refetchRequest,
    request,
    isLoading,
  } = useOfferRequest(Number(requestId));
  return (
    <>
      {isLoading ? (
        <SpinWrapperStyled>
          <Spin />
        </SpinWrapperStyled>
      ) : (
        request && (
          <PageWrapper>
            <BreadcrumbRequestPage
              isBuyingCrypto={request.offer?.is_buying_crypto}
            />
            <RequestInfoWrapper>
              <RequestInfo request={request} refetchRequest={refetchRequest} />
              <OfferRequestTabs request={request} />
            </RequestInfoWrapper>
          </PageWrapper>
        )
      )}
    </>
  );
};

export default OfferRequestPage;
