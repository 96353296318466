import { Tabs } from 'antd';
import { TabsProps } from '@pankod/refine-antd';
import { RequestTabs } from '../OfferRequestPage.styles';
import { Flex } from '../../../../shared/components/styled';
import DatesInfo from './request-history-tab-chilren/date-info/DatesInfo';
import { IRequest } from '../../model/types';
import dayjs from 'dayjs';
import OfferRequestHistoryTable from '../../../../features/offer-request-history-table';
import { useTranslation } from 'react-i18next';

interface IProps {
  request: IRequest;
}

const OfferRequestTabs = ({ request }: IProps) => {
  const { t } = useTranslation('shared');
  const formatDate = (date: string) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('shared:тексты.История сделки'),
      children: (
        <Flex gap={24} align={'stretch'} vertical>
          <DatesInfo
            dateCreated={formatDate(request.created_at)}
            dateFreezeRate={
              request.exchange_rate_freeze_datetime
                ? formatDate(request.exchange_rate_freeze_datetime)
                : undefined
            }
            dateFreezeExchange={
              request.exchange_freeze_datetime
                ? formatDate(request.exchange_freeze_datetime)
                : undefined
            }
            dateOverdue={
              request.exchange_datetime
                ? formatDate(request.exchange_datetime)
                : undefined
            }
          />
          <OfferRequestHistoryTable history={request?.history?.reverse()} />
        </Flex>
      ),
    },
  ];
  return <RequestTabs items={items} />;
};

export default OfferRequestTabs;
