import React, { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from '@pankod/refine-react-router-v6';

const CellDescription: React.FC<{
  title: string | ReactNode;
  description?: string;
  iconRightUp?: ReactNode;
  iconRightDown?: ReactNode;
  iconLeftUp?: ReactNode;
  iconLeftDown?: ReactNode;
  titleLink?: string;
}> = ({
  title,
  titleLink,
  description,
  iconRightUp,
  iconLeftUp,
  iconRightDown,
  iconLeftDown,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
  };
  return (
    <div>
      <TextContainer>
        {iconLeftUp}
        <MainTitle onClick={handleClick}>
          {!!titleLink ? <Link to={titleLink}>{title}</Link> : title}
        </MainTitle>
        {iconRightUp}
      </TextContainer>
      <TextContainer>
        {iconLeftDown}
        <DescriptionTitle>{description}</DescriptionTitle>
        {iconRightDown}
      </TextContainer>
    </div>
  );
};

export default CellDescription;

const MainTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;
const TextContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const DescriptionTitle = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0;
`;
