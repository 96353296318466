import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Draft, produce } from 'immer';
import {
  TActiveFilter,
  TFiltersData,
  TFilterSetType,
  TFilterSimple,
  TFilterSimpleWithId,
  TDeals,
  TDealsData,
  IAccount,
} from './types';
import { IClient, ICurrency, IExchangePoint, IMeta } from '../../../interfaces';

interface IDealsState {
  isFixCost: boolean;
  isFiltersCollapsed: boolean;
  directionFilters: TFilterSimple[];
  statusFilters: TFilterSimpleWithId[];
  fiatCurrFilters: TFilterSimple[];
  cryptoCurrFilters: TFilterSimple[];
  clientsFilters: TFilterSimple[];
  accountsFilters: TFilterSimpleWithId[];
  pointsFilters: TFilterSimpleWithId[];
  resetAll: () => void;
  setFilterInitial: (data: TFiltersData[]) => void;
  setFilter: (values: number[] | string, type: TFilterSetType) => void;
  setFilterCollapsed: (values: number | string, type: TFilterSetType) => void;
  setCost: (value: boolean) => void;
  setIsCollapsed: (value: boolean) => void;
  setDeals: (deals: TDealsData) => void;
  deals: TDeals[];
  setMeta: (value: number) => void;
  meta: IMeta;
  points: IExchangePoint[];
  recalcActiveFilters: () => void;
  activeFilters: TActiveFilter[];
}

export const useDealsState = create<IDealsState>()(
  devtools((set, get) => ({
    deals: [],
    meta: {} as IMeta,
    activeFilters: [],
    points: [],
    isFixCost: false,
    isFiltersCollapsed: true,
    directionFilters: [
      {
        name: 'buy',
        label: 'Покупка',
        t: 'shared:тексты.',
        checked: false,
      },
      {
        name: 'sell',
        label: 'Продажа',
        t: 'shared:тексты.',
        checked: false,
      },
    ],
    statusFilters: [
      {
        id: 1,
        name: 'accepted',
        label: 'Принята',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 2,
        name: 'cancelled',
        label: 'Отменена',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 3,
        name: 'warranty_collateral',
        label: 'Обеспечение гаранта',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 4,
        name: 'ready',
        label: 'Готова к обмену',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 5,
        name: 'expired',
        label: 'Просрочена',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 6,
        name: 'on_argument',
        label: 'Открыт спор',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 7,
        name: 'completed',
        label: 'Выполнена',
        t: 'shared:статусы.',
        checked: false,
      },
    ],
    clientsFilters: [],
    accountsFilters: [],
    pointsFilters: [],
    fiatCurrFilters: [],
    cryptoCurrFilters: [
      {
        name: 'USDTTRC20',
        checked: true,
        label: 'USDT',
      },
    ],
    resetAll: () =>
      set(
        produce((draft: Draft<IDealsState>) => {
          draft.directionFilters = draft.directionFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.statusFilters = draft.statusFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.pointsFilters = draft.pointsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.fiatCurrFilters = draft.fiatCurrFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.clientsFilters = draft.clientsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.accountsFilters = draft.accountsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.isFixCost = false;
        }),
        false,
        {
          type: 'useDealsState => resetAll',
        }
      ),
    setFilterInitial: (data) =>
      set(
        produce((draft: Draft<IDealsState>) => {
          data?.forEach((obj) => {
            if (obj.type === 'clients') {
              draft.clientsFilters = obj?.arr?.map((client) => ({
                id: Math.random(),
                name: client,
                label: client,
                checked: false,
              }));
            }

            if (obj.type === 'points') {
              draft.points = obj.arr;
              draft.pointsFilters = obj?.arr?.map((point) => ({
                id: point.id,
                name: point.name,
                label: point.name,
                checked: false,
              }));
            }
            if (obj.type === 'fiat') {
              draft.fiatCurrFilters = obj.arr.map((feat) => ({
                name: feat.code,
                label: feat.currency_key,
                checked: false,
              }));
            }

            if (obj.type === 'accounts') {
              draft.accountsFilters = obj?.arr?.map((fiat) => ({
                id: fiat.id,
                name: String(fiat?.id) as string,
                label: fiat?.name as string,
                checked: false,
              }));
            }
          });
        }),
        false,
        {
          type: 'useDealsState => setFilters',
        }
      ),
    setFilter: (value, type) =>
      set(
        produce((draft) => {
          if (
            (type === 'pointsFilters' ||
              type === 'statusFilters' ||
              type === 'accountsFilters' ||
              type === 'clientsFilters') &&
            Array.isArray(value)
          ) {
            draft[type] = draft[type].map((elem: IExchangePoint) => ({
              ...elem,
              checked: value.includes(elem.id),
            }));
          } else if (type !== 'pointsFilters') {
            draft[type] = draft[type].map(
              (elem: ICurrency | IClient | IAccount) => ({
                ...elem,
                checked: elem.name === value,
              })
            );
          }
        }),
        false,
        {
          type: 'useDealsState => setFilter',
        }
      ),
    setFilterCollapsed: (value, type) =>
      set(
        produce((draft) => {
          if (type === 'pointsFilters') {
            draft.pointsFilters = draft.pointsFilters.map(
              (elem: TFilterSimpleWithId) => ({
                ...elem,
                checked: elem.id === value ? false : elem.checked,
              })
            );
          }
          if (type !== 'pointsFilters') {
            draft[type] = draft[type].map((elem: TFilterSimple) => ({
              ...elem,
              checked: elem.name === value ? false : elem.checked,
            }));
          }
        }),
        false,
        {
          type: 'useDealsState => setFilter',
        }
      ),
    setCost: (value: boolean) => {
      set({ isFixCost: value });
    },
    setIsCollapsed: (value: boolean) => {
      set({ isFiltersCollapsed: value });
    },
    setDeals: (deals) =>
      set(
        produce((draft) => {
          draft.deals = deals.data;
          draft.meta = {
            ...draft.meta,
            total: deals.meta.total,
            last_page: deals.meta.last_page,
          };
        })
      ),
    setMeta: (pagination) =>
      set(
        produce((draft) => {
          draft.meta = { ...draft.meta, current_page: pagination };
        })
      ),
    recalcActiveFilters: () =>
      set(
        produce((draft: Draft<IDealsState>) => {
          const arr = [];
          const ccy = draft.cryptoCurrFilters.find((cur) => cur.checked);
          if (ccy) {
            arr.push({
              field: 'offer.crypto_currency_code',
              operator: '=',
              value: ccy.name,
            });
          }
          const cash = draft.fiatCurrFilters.find((cur) => cur.checked);
          if (cash) {
            arr.push({
              field: 'offer.cash_currency_code',
              operator: '=',
              value: cash.name,
            });
          }

          const status = draft.statusFilters
            .filter((st: { checked: boolean }) => st.checked)
            .map((st: { name: string }) => st.name);
          if (status.length) {
            arr.push({ field: 'status', operator: 'in', value: status });
          }

          const points = draft.pointsFilters
            .filter((st) => st.checked)
            .map((st) => st.id);
          if (points.length) {
            arr.push({
              field: 'offer.exchange_point_id',
              operator: 'in',
              value: points,
            });
          }
          const direction = draft.directionFilters.find((cur) => cur.checked);
          if (direction) {
            arr.push({
              field: 'offer.is_buying_crypto',
              operator: '=',
              value: direction.name === 'buy',
            });
          }

          const accounts = draft.accountsFilters
            .filter((st) => st.checked)
            .map((st) => st.id);
          if (accounts.length) {
            arr.push({
              field: 'cash_account_id',
              operator: 'in',
              value: accounts,
            });
          }

          const clients = draft.clientsFilters
            .filter((st) => st.checked)
            .map((st) => st.name);
          if (clients.length) {
            arr.push({
              field: 'nickname',
              operator: 'in',
              value: clients,
            });
          }

          draft.meta.current_page = 1;
          draft.activeFilters = arr;
        })
      ),
  }))
);
