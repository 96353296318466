import { TRequestForConfirmData } from '../model/useRequestConfirmState';
import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { IResponseShell } from '../../../shared/interfaces';
import { IOfferRequest } from '../../../shared/types/offers';

export enum EConfirmOfferRequestErrors {
  offerMustBeActive = 'Offer must be active',
  cryptoAmount = "Crypto amount mustn't exceed offer crypto_amount",
}

interface IConfirmOfferRequestError extends Error {
  errors: string[];
}

export class ConfirmOfferRequestError
  extends Error
  implements IConfirmOfferRequestError
{
  errors;
  constructor(errors: string[]) {
    super('Confirm request error');
    this.errors = errors;
  }
}

export const confirmOfferRequest = async (
  requestConfirmData: TRequestForConfirmData,
  requestId: number
) => {
  try {
    const { data } = await axios.put<IResponseShell<IOfferRequest>>(
      `${API_URL}/exchange-point-offer-requests/${requestId}/accept`,
      {
        ...requestConfirmData,
        latitude: String(requestConfirmData.latitude),
        longitude: String(requestConfirmData.longitude),
        exchange_datetime: `${requestConfirmData.exchange_date.format(
          'YYYY-MM-DD'
        )} ${requestConfirmData.exchange_time.format('HH:mm:ss')}`,
      }
    );
    return data.data;
  } catch (error: any) {
    const errors: string[] = [];

    if (error.response.data.errors.exchange_point_offer_id) {
      errors.push(...error.response.data.errors.exchange_point_offer_id);
    }
    if (error.response.data.errors.crypto_amount) {
      errors.push(...error.response.data.errors.crypto_amount);
    }

    if (errors.length) {
      throw new ConfirmOfferRequestError(errors);
    } else {
      throw new Error('Confirm request error');
    }
  }
};
